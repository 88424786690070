const BlogApi = [
  {
    id: 1,
    image: "./image/blog-01.jpg",
    date: "9 july, 2023",
    title_one: "Full Guide of Wireshark Tool",
    desc_one: "The Importance of Network Analysis - Highlights the significance of network analysis in various domains.Introducing Wireshark as a Network Analysis Tool - Presents Wireshark as a powerful and popular tool for network analysis. C. Overview of the Article Structure - Provides an outline of the article sections to follow.",
    title_two: "Understanding Wireshark",
    desc_two: "What is Wireshark? - Defines Wireshark as a network analysis tool and its primary functions. Wireshark Use Cases - Explores the different applications of Wireshark in network troubleshooting, security, and protocol analysis.Supported Platforms and Installation - Details the platforms on which Wireshark can be used and provides installation instructions for different platforms.",
    title_three: "Getting Started with Wireshark",
    desc_three: "Launching Wireshark and Interface Overview - Guides users on how to launch Wireshark and provides an overview of the interface.Capturing Network Traffic - Explains how to select network interfaces and capture packets using Wireshark.Analyzing Captured Data - Describes the process of analyzing captured data, including viewing packet lists, details, protocols, and streams.",
    url:"https://realtechsec.blogspot.com/2023/07/Wireshark%20.html",
  },
  {
    id: 2,
    image: "./image/blog-02.jpg",
    date: "8 july, 2023",
    title_one: "Enhancing Web Design and Styling for Beautiful User Interfaces.",
    desc_one: "Introduce CSS (Cascading Style Sheets) as a powerful styling language used in web development to enhance the visual appearance of web pages. Highlight its role in creating beautiful and engaging user interfaces. The keyword 'CSS' should be used to emphasize the central theme of the article.",
    title_two: "Understanding CSS: Basics and Syntax",
    desc_two: "Introduce the basics of CSS and its syntax. Cover selectors, properties, and values. Explain how CSS rules are applied to HTML elements to control their styling. The term 'CSS' should be integrated within discussions of its basics and syntax.",
    title_three: "Inline, Internal, and External CSS",
    desc_three: "Explain the different methods of including CSS in HTML documents. Discuss inline CSS, internal CSS within the head section, and external CSS files linked to HTML documents. The keyword 'CSS' should be included when discussing the inclusion methods.",
    url:"https://realtechsec.blogspot.com/2023/07/CSS.html",
  },
  {
    id: 3,
    image: "./image/blog-03.jpg",
    date: "20 Jun, 2021",
    title_one: "A Comprehensive Guide to the Powerful and User-Friendly Operating System.",
    desc_one: "Introduce Ubuntu Linux as a powerful and user-friendly operating system known for its stability, security, and wide range of applications. Highlight its popularity and widespread usage across desktops, servers, and cloud environments. The keyword 'Ubuntu Linux' should be used to emphasize the central theme of the article.",
    title_two: "Ubuntu Linux: Key Features and Advantages.",
    desc_two: "Highlight the key features and advantages that make Ubuntu Linux a popular choice. Discuss its open-source nature, ease of use, regular updates, software repositories, and strong security measures. The keyword 'Ubuntu Linux' should be used alongside discussions of its features and advantages.",
    title_three: "Installing Ubuntu Linux",
    desc_three: "Guide readers through the installation process of Ubuntu Linux. Discuss different installation methods, including dual-boot with other operating systems, virtual machine installation, and installation on bare metal. The term 'Ubuntu Linux' should be integrated within discussions of the installation process.",
    url: "https://realtechsec.blogspot.com/2023/07/ubuntu.html",
  },
]
export default BlogApi
