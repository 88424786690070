const Portfolio_data = [
  {
    id: 1,
    category: "WEB DEVELOPMENT",
    title: "Grannys Delight | Ecommerce Site",
    image: "./image/portfolio-01.jpg",
    disc: "Introducing Granny's Delight Baby Food Emporium – your one-stop destination for premium baby nutrition. Explore our curated selection of wholesome, organic baby food products, thoughtfully crafted to support your little one's growth and development. With a focus on quality and convenience, we make feeding time a breeze for busy parents. Shop now and give your baby the nourishment they deserve.",
    url: "https://grannysdelight.in",
  },
  {
    id: 2,
    category: "WEB DEVELOPMENT",
    title: "Surbhi Dairy | Ecommerce Showcase Site",
    image: "./image/portfolio-02.jpg",
    disc: "Introducing Surbhi Dairy – your premier destination for the finest dairy products. Discover our exquisite collection of farm-fresh milk, artisanal cheeses, velvety yogurts, and more. From breakfast essentials to gourmet delights, we bring you the richness and goodness of dairy, straight to your doorstep. Experience quality, taste, and convenience like never before. Start shopping now and indulge in dairy bliss with us!",
    url: "#",

  },
  {
    id: 3,
    category: "APPLICATION DEVELOPMENT",
    title: "Easy to Learn Android Application ",
    image: "./image/portfolio-03.jpg",
    disc: "Introducing Easy To Learn – your gateway to limitless knowledge. Dive into our expansive library of free courses covering a myriad of subjects, from coding to cooking, curated to empower learners of all levels. With user-friendly interfaces and interactive content, learning has never been more engaging. Join our community of lifelong learners today and unlock the doors to endless possibilities. Start your educational journey with FreeLearn now!",
    url: "#",
  },
  {
    id: 4,
    category: "WEB DEVELOPMENT",
    title: "Collage Placement Cell Management",
    image: "./image/portfolio-04.jpg",
    disc: "Introducing Collage Placement Cell Management – your ultimate platform for seamless college placement management. Built on the powerful MERN stack, our website streamlines the placement process, connecting students with prospective employers effortlessly. From job postings to resume submissions, we provide a comprehensive suite of tools to bridge the gap between academia and industry. Empower your students with the resources they need to succeed in their careers. Join us at Placement Hub and pave the way for brighter futures today!",
    url: "#",
  },
  {
    id: 5,
    category: "WEB DEVELOPMENT",
    title: "ATSIONursing",
    image: "./image/portfolio-05.png",
    disc: "Sanjay Bhokare College of Nursing, part of Sanjay Bhokare Group of Institutes, offers GNM and B.Sc Nursing programs. Our focus is on delivering high-quality nursing education. General Nursing and Midwifery (GNM) Bachelor of Science in Nursing (B.Sc)At Sanjay Bhokare Group of Institutes, we commit to delivering quality education aligned with societal needs. Our nursing college prepares students for compassionate, patient-centered care, fostering nursing excellence.",
    url: "https://www.atsionursing.org/",
  },

]
export default Portfolio_data
