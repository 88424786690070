import React from "react"
import logo1 from "./pic/logo.png"

const Footer = () => {
  return (
    <>

        <div className='footer text-center'>
          <div className='img1'>
            <img src={logo1} alt='' />
          </div>
          <p>© 2021. All rights reserved by Swapnil Harale.</p>
        </div>

    </>
  )
}

export default Footer
