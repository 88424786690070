import React, { useState } from "react";
import "../Portfolio/Portfolio.css";
import "../Blog/Blog.css";
import Card from "./Card";
import BlogApi from "./BlogApi";

const Blog = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  window.addEventListener("resize", () => {
    setIsMobile(window.innerWidth <= 768);
  });

  return (
    <>
      <section className='Portfolio Blog' id='blog'>
        <div className='container top'>
          <div className='heading text-center'>
            <h4>VISIT MY BLOG AND KEEP YOUR FEEDBACK</h4>
            <h1>My Blog</h1>
          </div>

          <div className={isMobile ? "content " : "content grid "}>
            {BlogApi.map((value, index) => {
              return (
                <Card
                  key={index}
                  url={value.url}
                  image={value.image}
                  date={value.date}
                  title_one={value.title_one}
                  title_two={value.title_two}
                  title_three={value.title_three}
                  desc_one={value.desc_one}
                  desc_two={value.desc_two}
                  desc_three={value.desc_three}
                />
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default Blog;
