import React, { useState } from "react"



const Card = (props) => {
  const [modal, setModal] = useState(false)

  const toggleModal = () => {
    setModal(!modal)
  }

  if (modal) {
    document.body.classList.add("active-modal")
  } else {
    document.body.classList.remove("active-modal")
  }
  return (
    <>
      <div className="card-q">
        <div className='box btn_shadow '>
          <div className='img' style={{}}>
            <img src={props.image} alt='' onClick={toggleModal} />
          </div>
          <div className='category d_flex'>
            <span onClick={toggleModal}>{props.category}</span>
            {/* <label>
            <i className='far fa-heart'></i> {props.totalLike}
          </label> */}
          </div>
          <div className='title'>
            <h2 onClick={toggleModal}>{props.title}</h2>
            <a href='#popup' className='arrow' onClick={toggleModal}>
              <i class='fas fa-arrow-right'></i>
            </a>
          </div>
        </div>
      </div >

      {/* Popup box */}
      {
        modal && (
          <div className='modal'>
            <div onClick={toggleModal} className='overlay'></div>
            <div className='modal-content d_flex'>
              <div className='modal-img left'>
                <img src={props.image} alt='' />
              </div>
              <div className='modal-text right'>
                <span>{props.category}</span>
                <h1>{props.title}</h1>
                <p>{props.disc}</p>
                <div className='button f_flex mtop'>
                  <button className='btn_shadow'>
                    LIKE THIS <i class='far fa-thumbs-up'></i>
                  </button>

                  <a className='btn_shadow' href={props.url}>VIEW PROJECT
                    <span> </span><i className='fas fa-chevron-right'></i>
                  </a>

                </div>
                <button className='close-modal btn_shadow' onClick={toggleModal}>
                  <i class='fas fa-times'></i>
                </button>
              </div>
            </div>
          </div>
        )
      }
    </>
  )
}

export default Card
