const ResumeApi = [
  {
    id: 1,
    category: "education",
    year: "DBATU University(2021 - 2024)",
    title: "BTech Computer Engineering",
    desc: "Our dedicated faculty members provide mentorship and guidance, nurturing students' skills and preparing them for real-world challenges in the field.",
    rate: "8/10",
  },
  // {
  //   id: 2,
  //   category: "education",
  //   year: "MSBTE University(2017 - 2021) ",
  //   title: "Diploma Civil Engineering",
  //   desc: "Despite completing my diploma in civil engineering for various reasons, I am drawn to the dynamic and innovative nature of computer engineering. ",
  //   rate: "5/10",
  // },
  // {
  //   id: 4,
  //   category: "Techskills",
  //   year: "CyberSecurity Verzeo 2022",
  //   title: "Cyber Security Expert",
  //   desc: "my journey through a cybersecurity internship and course, during which I completed live projects. I've tackled significant problem statements, demonstrating my ability to address real-world challenges in the cybersecurity field.",
  //   rate: "10/10 ",
  // },
  {
    id: 5,
    category: "Techskills",
    year: "2022-2024",
    title: "Full Stack Developer",
    desc: "my expertise as a frontend developer, proficient in JAVA, SQL, HTML, CSS, JavaScript, React.js, and Bootstrap. It showcases my ability to create engaging and responsive web experiences using these technologies.",
    rate: "9.5/10 ",
  },
  // {
  //   id: 6,
  //   category: "Techskills",
  //   year: "2023-2024",
  //   title: "Android App Development",
  //   desc: "my proficiency in Android app development, utilizing Java and XML within Android Studio. It showcases applications I've developed, demonstrating my skills in building functional and user-friendly mobile experiences.",
  //   rate: "7/10 ",
  // },
]

export default ResumeApi
